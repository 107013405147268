import { graphql } from 'gatsby';
import * as React from 'react';
import DefaultPageContainer from '../components/default-page-container/DefaultPageContainer';

const NotFoundPage = () => (
  <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
    <h1>🛑</h1>
  </DefaultPageContainer>
);

export default NotFoundPage;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: { language: { in: [$language, "en"] } }) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;
