import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const StyledBox = styled(Box)``;

const PagePad = ({ }: any) => {
  const size = React.useContext(ResponsiveContext);

  return <StyledBox height={size === 'small' ? '60px' : '100px'} />;
};

export default PagePad;
