import * as React from 'react';
import { Box, Footer, Text } from 'grommet';
import styled, { css } from 'styled-components';
import PagePad from '../page-pad/PagePad';
import { BottomNavigation } from '../bottom-navigation/BottomNavigation';

const StyledBox = styled(Box)`
${props => {
    const {
      theme: {
        global: { breakpoints },
      },
    } = props;
    return css`
      min-height: calc(100% - 40px);
      max-width: 100%;
      overflow-x: hidden;
    `;
  }}
`;

const DefaultPageContainer = ({ children, ...props }: any) => (
  <>
    <StyledBox {...props}>
      <PagePad />
      {children}
    </StyledBox>

  </>
);

export default DefaultPageContainer;
